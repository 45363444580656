<template>
    <div v-if="this.pageData && this.eventData">
        <HeaderIntroImage marginbottom :headerMetadata="this.pageData" 
            :menuparent="
                formatdate + ' - ' + eventData.attributes.field_event_type +
                '<br>' + formathours" 
        />
        <main>
            <section class="main-content" v-if="this.eventData.attributes">
                <div :class="['preview-panel', this.showPreview ? '' : 'hidden']" @click="togglePreview('', '')">
                    <p class="image">
                        <img :src="previewUrl" :alt="previewAlt">
                    </p>
                    <button class="close">close</button>
                </div>


                <div class="container">
                   
                    <div class="margin video-container" v-if="this.eventData.attributes.field_youtube_video_code">
                        <iframe allow="accelerometer; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                            frameborder="0"
                            class="image" 
                            :src="'https://www.youtube.com/embed/' + this.eventData.attributes.field_youtube_video_code">
                        </iframe>
                    </div>

                    <div v-html="eventData.attributes.field_description_w_summary.value"></div>

                    <div class="margin attachments-container" v-if="eventData.field_attachment.length">
                        <h3>Attachments</h3>
                        <a v-for="(att, ind) in eventData.field_attachment" :key="'attach'+ind"
                            class="attachments" :href="endpoint + att.attributes.uri.url"
                            download target="_blank"> {{ getAttDescription(ind) }} </a>
                    </div>

                    <div class="gallery margin" v-if="eventData.field_photogallery">
                        <p class="image" v-for="(img, ind) in eventData.field_photogallery" :key="'galleryimg'+ind" 
                            @click="togglePreview(img.attributes.uri.url, eventData.relationships.field_photogallery.data[ind].meta.alt)">
                            <img :src="newsimg" :alt="eventData.relationships.field_image.data.meta.alt">
                        </p>    
                    </div>

                    <p class="image" v-if="this.eventData.field_image">
                        <img :src="newsimg" :alt="this.eventData.relationships.field_image.data.meta.alt">
                    </p>
                </div>
            </section>
        </main>
    </div>
</template>
<script>
import { fetchRoutes, fetchNodes } from '../../libs/drupalClient'
import HeaderIntroImage from '../../components/layout-blocks/HeaderIntroImage.vue'
    export default {
        name: 'event',
        components: {
            HeaderIntroImage
        },
        data: () => {
            return {
                pageData: {
                    attributes: {
                        title: ""
                    },
                    field_banner_image: {
                        attributes: {
                            uri: {
                                url: ""
                            }
                        }
                    }
                },
                routesData: null,
                currentNid: '',
                eventData: null,
                showPreview: false,
                previewUrl: '',
                previewAlt: ''
            }
        },
        computed: {
            newsimg() {
                if(this.eventData)
                    return process.env.VUE_APP_ENDPOINT + this.eventData.field_image.attributes.uri.url
                else 
                    return ''
            },
            endpoint (){
                return process.env.VUE_APP_ENDPOINT
            },
            objecttitle() {
                return this.$route.params.title
            },
            formatdate() {
                if(this.eventData) {
                    var start = new Date(this.eventData.attributes.field_start_date).toLocaleString('en', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                    })

                    var end = new Date(this.eventData.attributes.field_end_date).toLocaleString('en', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                    })
                
                    if(start == end)
                        return start
                    else
                        return 'From ' + start + ' to ' + end
                } else 
                    return ''
            },
            formathours() {
                if(this.eventData) {
                    var start = new Date(this.eventData.attributes.field_start_date).toLocaleTimeString('en' , { 
                        hour: '2-digit', 
                        minute: '2-digit'
                    })

                    var end = new Date(this.eventData.attributes.field_end_date).toLocaleTimeString('en' , { 
                        hour: '2-digit', 
                        minute: '2-digit'
                    })

                    if(start==end)
                        return 'At ' + start
                    else
                        return 'Starting at ' + start + ' until ' + end
                } else return ''
            }
        },
        methods: {
            togglePreview(url, alt) {
                this.previewUrl = process.env.VUE_APP_ENDPOINT + url
                this.previewAlt = alt
                this.showPreview = !this.showPreview
            },
            getAttDescription(i) {
                var d = this.eventData.relationships.field_attachment.data[i].meta.description
                if(d=='')
                    d = this.eventData.field_attachment[i].attributes.filename
                
                return d
            }
        },
        async mounted() {
            await fetchRoutes().then(res => {
                this.routesData = res.routes
                var t = this.routesData.filter(rr => rr.url_check==this.objecttitle)

                if(t.length>0) {
                    this.currentNid = t[0].nid
                    //console.log(t[0].url_check, t[0].nid, this.currentNid)

                    fetchNodes('event', {
                        include: ['field_image', 'field_attachment', 'field_photogallery'],
                        filters: [{
                            key: 'drupal_internal__nid',
                            value: this.currentNid
                        }]
                    }).then(res => {
                        this.eventData = res[0]
                        this.pageData.attributes.title = this.eventData.attributes.title
                        //console.log('detail of event', this.eventData)
                    })
                } else {
                    this.$router.push('/not-found')
                }
            })
        },
        watch: {
            objecttitle() {
                fetchRoutes().then(res => {
                    this.routesData = res.routes
                    var t = this.routesData.filter(rr => rr.url_check==this.objecttitle)
                    
                    if(t.length>0) {
                        this.currentNid = t[0].nid
                        //console.log(t[0].url_check, t[0].nid, this.currentNid)

                        fetchNodes('news', {
                            include: ['field_image', 'field_attachment'],
                            filters: [{
                                key: 'drupal_internal__nid',
                                value: this.currentNid
                            }]
                        }).then(res => {
                            this.eventData = res[0]
                            this.pageData.attributes.title = this.eventData.attributes.title
                            //console.log('detail of news', this.eventData)
                        })
                    } else {
                        this.$router.push('/not-found')
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
@import '../../styles/variables.scss';

.image {
    width: 100%;
    img {
        width: 100%
    }
}

.video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
}

iframe { 
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.gallery {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 2rem;
    gap: 1em;
    .image { 
        margin-top: 0;
        margin-bottom: 0;
        &:hover {
            position: relative;
            cursor: pointer;
            &:after {
                width: 100%;
                height: 100%;
                display: block;
                pointer-events: none;
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                background: rgba(#000, .2);
            }
        }
    }
}


.preview-panel {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 5;
    height: 100vh;
    background-color: rgba(#000, .5);
    display: block;
    //pointer-events: none;
    &.hidden { display: none; }

    .close {
        background-color: $orange;
        position: absolute;
        //pointer-events: all;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
    }

    .image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
    }

}


@media (min-width: 768px) {
    .gallery {
        grid-template-columns: repeat(2, 1fr);
        .image { 
            //aspect-ratio: 1;
            img {
                //height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }
    }
}

@media (min-width: 1024px) {
    .gallery {
        grid-template-columns: repeat(3, 1fr);
    }
    .preview-panel {
        p.image {
            width: 80%;
            top: 55%;
        }
    }
}

@media (min-width: 1300px) {

    .preview-panel p.image {
        height: 70%;
    }


    .preview-panel p {
        max-width: 1200px;
    }

    .video-container {
        padding-bottom: 44%;
    }
}
</style>